<template>
  <b-sidebar
    id="element-sidebar"
    :visible="isToggleSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => toggleSidebar(val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titleForm }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-form-group
          label="Producto"
          label-for="producto"
        >
          <v-select
            id="producto"
            v-model="productoSel"
            label="descripcion"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="productos"
            :clearable="false"
          />
        </b-form-group>

        <b-form-group
          label="Cantidad"
          label-for="cantidad"
        >
          <b-input-group
            append="UND"
          >
            <b-form-input
              id="cantidad"
              v-model="formData.cantidad"
              autofocus
              trim
              placeholder=""
              type="number"
            />
          </b-input-group>
        </b-form-group>
        <b-form-group
          label="Periodo"
          label-for="periodo"
        >
          <b-input-group
            :prepend="currentYear"
          >
            <v-select
              v-model="periodoSel"
              :reduce="m => m.value"
              label="text"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="mesesPeriodo"
              :clearable="false"
            />
          </b-input-group>
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="button"
            @click="saveForm"
          >
            Guardar
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="hide"
          >
            Cancelar
          </b-button>
        </div>

      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BInputGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleSidebar',
    event: 'update:is-toggle-sidebar',
  },
  props: {
    isToggleSidebar: {
      type: Boolean,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
    formType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const titleForm = ref('')
    const formData = ref({})
    const periodoSel = ref('')
    const productos = ref([])
    const productoSel = ref({})
    const currentYear = new Date().getFullYear().toString()

    const resetForm = () => {
      formData.value = {}
      productoSel.value = {}
    }

    const mesesPeriodo = [
      {
        text: 'ENERO',
        value: '01',
      },
      {
        text: 'FEBRERO',
        value: '02',
      },
      {
        text: 'MARZO',
        value: '03',
      },
      {
        text: 'ABRIL',
        value: '04',
      },
      {
        text: 'MAYO',
        value: '05',
      },
      {
        text: 'JUNIO',
        value: '06',
      },
      {
        text: 'JULIO',
        value: '07',
      },
      {
        text: 'AGOSTO',
        value: '08',
      },
      {
        text: 'SETIEMBRE',
        value: '09',
      },
      {
        text: 'OCTUBRE',
        value: '10',
      },
      {
        text: 'NOVIEMBRE',
        value: '11',
      },
      {
        text: 'DICIEMBRE',
        value: '12',
      },
    ]

    const getProductos = async () => {
      await store.dispatch('comercial/PRODUCTO_FIND_ALL', {
        query: '',
        page: 1,
        limit: 100,
        tipo: 'cmb',
        sortBy: 'idProducto%7CASC',
      })
        .then(response => {
          if (response) {
            response.forEach(item => {
              // eslint-disable-next-line no-param-reassign
              item.descripcion = `${item.presentacion.descripcion} - ${item.presentacion.marca.descripcion}`
            })
            productos.value = response
          }
        })
    }

    const saveForm = () => {
      if (productoSel.value === null) {
        emit('error-data', { response: { data: { message: 'Debe seleccionar producto.' } } })
        return
      }
      let service = 'inventario/TRASEGADO_CREATE'
      if (props.formType === 'edit') {
        service = 'inventario/TRASEGADO_CREATE'
      }
      formData.value.idProducto = productoSel.value.idProducto

      const payload = new FormData()
      payload.append('idProducto', productoSel.value.idProducto)
      payload.append('cantidad', parseInt(formData.value.cantidad, 10))
      payload.append('periodo', parseInt(currentYear + formData.value.periodo, 10))
      store.dispatch(service, payload)
        .then(response => {
          emit('refetch-data', response)
          emit('update:is-toggle-sidebar', false)
        })
        .catch(error => {
          emit('error-data', error)
          emit('update:is-toggle-sidebar', false)
        })
    }

    const toggleSidebar = async val => {
      emit('update:is-toggle-sidebar', val)
      resetForm()
      await getProductos()
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Trasegado'
        productoSel.value = productos.value.filter(prod => prod.idProducto === 2)
        const month = new Date().getMonth() + 1
        periodoSel.value = month.toString().padStart(2, '0')
      }
      if (props.formType === 'edit') {
        formData.value = props.dataEdit
        productoSel.value = props.dataEdit.producto
        titleForm.value = 'Editar Trasegado'
      }
    }

    return {
      formData,
      saveForm,
      resetForm,
      toggleSidebar,
      titleForm,
      getProductos,
      productos,
      productoSel,
      currentYear,
      mesesPeriodo,
      periodoSel,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#element-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.input-group {
  display: flex;
}
.input-group .vs--searchable {
  flex: 1;
}
</style>
