<template>
  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <trasegado-form
      :is-toggle-sidebar.sync="isToggleSidebar"
      :form-type="formType"
      :data-edit="dataEdit"
      @refetch-data="listarRegistros"
      @error-data="showError"
    />

    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Mostrando</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="pageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <label>registros</label>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="query"
              class="d-inline-block mr-1"
              placeholder="Buscar por periodo, ejemplo: 202401..."
            />
            <b-button
              variant="primary"
              @click="openForm('new')"
            >
              <span class="text-nowrap">Agregar</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

    </div>

    <b-table
      ref="refListTable"
      :items="items"
      responsive
      :fields="fields"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="No se encontraron registros"
      class="position-relative"
      :busy="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner
            variant="primary"
            class="align-middle"
          />
          <strong class="ml-1">Cargando...</strong>
        </div>
      </template>

      <template #cell(cantidad)="data">
        <b-media vertical-align="center">
          <template #aside>
            <b-avatar
              :id="`cis-row-${data.item.idTrasegadoCilindro}`"
              size="32"
              variant="light-primary"
            >
              <font-awesome-icon
                icon="fa-solid fa-arrows-turn-to-dots"
              />
            </b-avatar>
          </template>
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.producto.presentacion.descripcion }} - {{ data.item.producto.presentacion.marca.descripcion }}
          </span>
          <small class="text-muted d-block">Cantidad {{ data.item.cantidad }}</small>
          <small class="text-muted d-block">Stock Afectado {{ data.item.cantidadSinAfectar }} >> {{ data.item.cantidadAfectada }}</small>
        </b-media>
      </template>

      <!-- Column: Individuo -->
      <template #cell(periodo)="data">
        <div class="font-small-3">
          {{ formatPeriod(data.item.periodo) }}
        </div>
      </template>

      <template #cell(fechaOperacion)="data">
        <div>
          <div>
            <div class="font-small-3">
              <feather-icon icon="CalendarIcon" />
              {{ data.item.fechaOperacion }}
            </div>
            <div class="font-small-2 text-muted">
              {{ data.item.usuario }}
            </div>
          </div>
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(acciones)="data">

        <div class="text-nowrap">
          <b-button
            size="sm"
            variant="danger"
            @click="getDelete(data)"
          >
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Eliminar</span>
          </b-button>
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ from }} a {{ to }} de {{ of }} registros</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="page"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import Vue from 'vue'
import { ref, watch } from '@vue/composition-api'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BPagination, BSpinner,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import store from '@/store'
import { useNotify } from '@/helpers/toast'
import { formatPeriod } from '@/helpers/dates'
import TrasegadoForm from './TrasegadoForm.vue'

export default {
  components: {
    TrasegadoForm,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BPagination,
    BSpinner,

    vSelect,
  },
  setup(_, context) {
    const { notify } = useNotify(context)
    const isBusy = ref(false)
    const items = ref([])
    const fields = ref([
      { key: 'idTrasegadoCilindro', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'cantidad', label: 'Trasegado', sortable: true, thClass: 'text-left', tdClass: 'text-left' },
      { key: 'periodo', label: 'Periodo', sortable: true, thClass: 'text-center', tdClass: 'text-center' },
      { key: 'fechaOperacion', label: 'Fecha Operación', sortable: true, thClass: 'text-center', tdClass: 'text-center' },
      { key: 'acciones', tdClass: ['w100', 'text-center'], thClass: 'text-center' },
    ])

    const page = ref(1)
    const perPage = ref(10)
    const sortBy = ref('idTrasegadoCilindro%7CDESC')
    const query = ref('')
    const totalRows = ref(0)
    const totalItems = ref(0)
    const totalPage = ref(0)
    const from = ref(0)
    const to = ref(0)
    const of = ref(0)
    const pageOptions = ref([10, 20, 50])
    const ftPeriodo = ref('')

    const isToggleSidebar = ref(false)
    const formType = ref('')
    const dataEdit = ref({})

    const showError = error => {
      const msgError = error?.response?.data?.message ? error.response.data.message : 'Ha ocurrido un error'
      notify('Error', msgError, 'danger')
    }

    const openForm = type => {
      formType.value = type
      isToggleSidebar.value = true
    }

    const listarRegistros = async () => {
      isBusy.value = true
      await store.dispatch('inventario/TRASEGADO_FIND_ALL', {
        query: query.value,
        page: page.value,
        limit: perPage.value,
        sortBy: sortBy.value,
        periodo: ftPeriodo.value,
        tipo: 'grilla',
      })
        .then(response => {
          if (response) {
            items.value = response.items
            totalRows.value = response.totalRows
            totalItems.value = response.totalItems
            totalPage.value = response.totalPage
            from.value = perPage.value * (page.value - 1) + (totalRows.value ? 1 : 0)
            to.value = perPage.value * (page.value - 1) + totalRows.value
            of.value = totalItems.value
          }
        })
      isBusy.value = false
    }

    const getDelete = item => {
      console.log('checame >', item)
      Vue.swal({
        title: 'Está seguro?',
        text: 'Va eliminar el trasegado!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sí',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store
            .dispatch('inventario/TRASEGADO_DELETE', {
              id: item.item.idTrasegadoCilindro,
            })
            .then(() => {
              notify('Operación Exitosa', 'El proceso se ha ejecutado satisfactoriamente.', 'success')
              listarRegistros()
            })
            .catch(error => {
              let errorMessage = 'Intentelo en otro momento.'
              if (error.response) {
                errorMessage = error.response.data.message
              }
              notify('Ha ocurrido un error', errorMessage, 'danger')
            })
        }
      })
    }

    watch([page, perPage, query], () => {
      listarRegistros()
    })

    listarRegistros()

    return {
      avatarText,
      isBusy,
      sortBy,
      page,
      perPage,
      query,
      items,
      fields,
      listarRegistros,
      totalRows,
      totalItems,
      totalPage,
      from,
      to,
      of,
      pageOptions,
      openForm,
      isToggleSidebar,
      formType,
      dataEdit,
      showError,
      formatPeriod,
      getDelete,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.w100{
  width: 100px !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
